.footerLinks {
  display: none;
}
@media screen and (max-width: 1023px) {
  .footerLinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 767px) {
  .footerLinks {
    display: none;
  }
  .footerLinksSmall {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  .footerLinksSmall {
    display: none;
  }
}

.footerDiv {
  display: flex;
}

.footerElement {
  display: flex;
}
