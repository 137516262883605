@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Great+Vibes&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Raleway", sans-serif;
  }
}

.custom-toastr {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  z-index: 9999;
}

.custom-toastr.error {
  background-color: #ff6347; /* Red */
}

.custom-toastr.success {
  background-color: #32cd32; /* Green */
}
